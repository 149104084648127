import {
  ClearButton,
  Flex,
  IconItem,
  PublishIcon,
  toastError,
  toastSuccess,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import _ from "lodash";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Separator from "../../components/ui/separator/Separator";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import AdActionsButton from "../../widgets/ads/AdActionsButton";
import { checkFormRequiredFields } from "../../widgets/ads/AdStatusAction";
import { handleCRUD } from "../crud/functions";
import { useTranslation } from "../hooks";
import {
  adResponseStatus,
  adsActionsTypes,
  adStatus,
  orgStatus,
} from "../variables";
import { parseStatusToPublishPermission } from "./functions";

const errorToast = (errorMessage) =>
  toastError(errorMessage, { toastId: "ad_error" }, "sentiment_dissatisfied");

const parseAdToRemoveUselessFields = (ad, newStatus) => {
  const newData = { ...ad, amount: ad?.amount * 1, price: ad?.price * 1 };
  delete newData.__typename;
  delete newData.transactionsActive;
  delete newData.updatedadDate;
  delete newData.views;
  delete newData.likes;
  delete newData.uuidOrg;
  delete newData.materialType;
  delete newData.materialComposition;
  if (!_.isEmpty(newData?.files)) {
    newData["images"] = newData?.files.map((f) => f.uuid);
  } else {
    newData["images"] = [];
  }
  delete newData.files;
  newData["materialType"] = newData?.materialTypeEdit;
  if (_.isObject(newData["materialType"])) {
    newData["materialType"] = newData["materialType"].uuid;
  }
  delete newData?.materialTypeEdit;
  if (!_.isEmpty(newData?.materialSize)) {
    newData["materialSize"] = newData["materialSize"] * 1;
  }
  if (!_.isEmpty(newData?.frequencyAmount)) {
    newData["frequencyAmount"] = newData["frequencyAmount"] * 1;
  }

  if (newData["frequencyAmount"] === "" || newData["frequencyAmount"] === 0) {
    delete newData["frequencyAmount"];
  }

  if (newData?.frequency === 0 || newData?.frequency === "") {
    delete newData["frequency"];
  }

  if (!_.isEmpty(newData["loadCharacteristics"])) {
    newData["loadCharacteristics"] = newData["loadCharacteristics"]?.map(
      (characteristic) => {
        if (!_.isEmpty(characteristic.value)) {
          Object.keys(characteristic.value).forEach((key) => {
            characteristic.value[key] = characteristic.value[key] * 100;
          });
        }
        return characteristic;
      }
    );
  }

  if (!_.isEmpty(newData?.lerCodes)) {
    newData["lerCodes"] = newData?.lerCodes.map((lc) => {
      if (_.isObject(lc)) {
        return lc.name;
      }
      return lc;
    });
  }

  if (!_.isEmpty(newData?.components)) {
    newData["components"] = newData?.components.map((c) => {
      if (_.isObject(c)) {
        return {
          uuid: c?.uuid,
          type: c?.type,
          text: c?.text || c?.materialDesc || c,
          value: `${c?.value || c?.percentage}`,
          operator: c?.operator || "equal",
        };
      }
      return c;
    });
  }

  if (!_.isEmpty(newData?.materialQuestions)) {
    newData?.materialQuestions.forEach((mq) => {
      if (!_.isEmpty(mq?.value)) {
        mq.value = mq.value.map((mqv) => `${mqv}`);
      }
    });
  }

  if (ad?.address?.countryCode) {
    newData["address"].countryCode = newData["address"].countryCode * 1;
  }

  if (!_.isEmpty(ad?.address?.port) && _.isObject(ad?.address?.port)) {
    newData["address"] = {
      port: "",
    };
    newData["address"].port = ad?.address?.port.uuid;
  }

  try {
    delete ad?.address?.__typename;
    delete ad?.address?.facilities;
    if (_.isEmpty(ad?.address?.id)) {
      delete ad?.address?.id;
    }
  } catch (error) {
    console.log(error);
  }

  if (!_.isEmpty(ad?.incoterm) && _.isObject(ad?.incoterm)) {
    newData["incoterm"] = ad?.incoterm?.uuid;
  }

  if (!_.isEmpty(ad?.paymentMethod) && _.isObject(ad?.paymentMethod)) {
    newData["paymentMethod"] = ad?.paymentMethod?.uuid;
  }

  if (_.isEmpty(newData["description"])) {
    newData["description"] = "-";
  }

  if (newStatus) {
    newData.status = newStatus;
  }

  if (ad?.type === "sale") {
    delete newData["incoterm"];
    delete newData["paymentMethod"];
  }

  return newData;
};

export const useAdActions = (props = {}) => {
  const { t, i18n } = useTranslation(["common", "ads"], true);
  const [open, setOpen] = useState(false);
  const [titleMessage, setTitleMessage] = useState(t("sure-apply-changes"));
  const [bodyMessage, setBodyMessage] = useState(t("need-client-consent"));
  const [okMessage, setOkMessage] = useState(t("save-changes"));
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [type, setType] = useState(t("save-changes"));
  const navigate = useNavigate();
  const errorMessage = useRef(t("ad-action-error"));

  const changeValues = (data) => {
    setOpen(true);
    setTitleMessage(data?.title);
    setBodyMessage(data?.body);
    setOkMessage(data?.ok);
    setType(data?.type);
  };
  const handleShare = () => {
    const { ad, id } = props;
    if (id) {
      navigator.clipboard
        .writeText(
          `${process.env.REACT_APP_MARKETPLACE_URL}/${i18n.language}/ad/${id}`
        )
        .then(
          function () {
            toastSuccess(t("ad-copied"), { toastId: "ad_share" }, "share");
          },
          function () {
            errorToast();
          }
        );
    }
  };
  const handleEdit = () => {
    changeValues({
      title: t("sure-apply-changes"),
      body: t("need-client-consent"),
      ok: t("edit"),
      type: adsActionsTypes.EDIT,
    });
  };
  const handleUnpublish = () => {
    const { transactions } = props;
    transactions && parseInt(transactions) > 0
      ? changeValues({
          title: t("ad-cant-unpublish"),
          body: t("ad-cant-unpublish-text"),
          ok: "",
          type: adsActionsTypes.CANT_UNPUBLISH,
        })
      : changeValues({
          title: t("ad-sure-unpublish"),
          body: t("ad-unpublish-text"),
          ok: t("ad-unpublish-sure"),
          type: adsActionsTypes.UNPUBLISH,
        });
  };
  const handleCantUnpublish = () => {
    changeValues({
      title: t("ad-cant-unpublish"),
      body: t("ad-cant-unpublish-text"),
      ok: "",
      type: adsActionsTypes.CANT_UNPUBLISH,
    });
  };
  const handleCopy = () => {
    changeValues({
      title: t("ad-sure-clone"),
      body: t("ad-clone-text"),
      ok: t("ad-clone"),
      type: adsActionsTypes.CLONE,
    });
  };
  const handleBlock = () => {
    changeValues({
      title: t("ad-sure-block"),
      body: t("ad-block-text"),
      ok: t("ad-block"),
      type: adsActionsTypes.BLOCK,
    });
  };
  const handleUnBlock = () => {
    changeValues({
      title: t("ad-sure-unblock"),
      body: t("ad-unblock-text"),
      ok: t("ad-unblock"),
      type: adsActionsTypes.UNBLOCK,
    });
  };
  const handlePublish = () => {
    changeValues({
      title: t("ad-sure-publish"),
      body: t("ad-publish-text"),
      ok: t("ad-publish"),
      type: adsActionsTypes.PUBLISH,
    });
  };

  const handleREstore = () => {
    changeValues({
      title: t("ad-sure-restore"),
      body: t("ad-restore-text"),
      ok: t("ad-restore"),
      type: adsActionsTypes.RESTORE,
    });
  };

  const shareAction = (
    <IconItem
      name={"share"}
      onClick={handleShare}
      text={t("share")}
      color={WARM_NEUTRAL_60}
    />
  );

  const editAction = (
    <IconItem
      name={"edit"}
      onClick={handleEdit}
      text={t("edit")}
      color={WARM_NEUTRAL_60}
    />
  );

  const handleDemandCovered = () => {
    changeValues({
      title: t("cant-publish-ad"),
      body: t("cant-publish-ad-reason"),
      ok: t("ad-unpublish-sure"),
      type: adsActionsTypes.DEMAND_COVERED,
    });
  };

  const publishAction = (
    <IconItem
      onClick={handlePublish}
      text={t("publish")}
      customRenderIcon={() => {
        return <PublishIcon />;
      }}
    />
  );

  const unpublishAction = (
    <IconItem
      name={"disabled_visible"}
      onClick={handleUnpublish}
      text={t("unpublish")}
      color={WARM_NEUTRAL_60}
    />
  );

  const cantUnpublishAction = (
    <IconItem
      name={"disabled_visible"}
      onClick={handleCantUnpublish}
      text={t("unpublish")}
      color={WARM_NEUTRAL_60}
    />
  );

  const cloneAction = (
    <IconItem
      name={"content_copy"}
      onClick={handleCopy}
      text={t("clone")}
      color={WARM_NEUTRAL_60}
    />
  );

  const blockAction = (
    <IconItem
      name={"block"}
      onClick={handleBlock}
      text={t("block")}
      color={WARM_NEUTRAL_60}
    />
  );

  const unBlockAction = (
    <IconItem
      name={"block"}
      onClick={handleUnBlock}
      text={t("unblock")}
      color={WARM_NEUTRAL_60}
    />
  );

  const restoreAction = (
    <IconItem
      name={"u_turn_right"}
      onClick={handleREstore}
      text={t("unblock")}
      color={WARM_NEUTRAL_60}
      iconStyle={{
        transform: "rotate(-90deg)",
      }}
    />
  );

  const handleStateChange = async (
    state,
    callback,
    uuid,
    fetchData,
    setLoading,
    setOpen,
    ad,
    fromDetail
  ) => {
    setLoading(true);
    const newData = ad ? parseAdToRemoveUselessFields(ad) : undefined;
    const endpoint = fromDetail ? `ads/${uuid}` : `ads/${uuid}?state=${state}`;
    if (fromDetail) {
      newData.status = state;
    }
    await handleCRUD({
      endpoint: endpoint,
      method: "PUT",
      data: ad ? newData : undefined,
      successCallback: async (response) => {
        if (callback) callback(response);
        await fetchData(ad);
        setLoading(false);
        setOpen(false);
      },
      customError: () => {
        errorToast();
        setLoading(false);
        setOpen(false);
      },
      signOut,
      setDataUser,
    });
  };

  const handleEditAction = (uuid) => {
    setOpen(false);
    navigate(`/ads/${uuid}/edit`);
  };

  const handlePublishChanges = async ({ ad, uuid, setLoading, fetchData }) => {
    setLoading(true);
    const newData = parseAdToRemoveUselessFields(ad);
    await handleCRUD({
      endpoint: `ads/${uuid}`,
      method: "PUT",
      data: newData,
      successCallback: async (response) => {
        if (
          response === adResponseStatus.CREATED ||
          response === adResponseStatus.UPDATED
        ) {
          toastSuccess(
            t("saved-changes-ok"),
            { toastId: "ad_clone" },
            "check_circle"
          );
        } else {
          toastSuccess(t("ad-moderated"), { toastId: "ad_clone" }, "block");
        }
        await fetchData(ad);
        navigate(`/ads/${uuid}`);
        setLoading(false);
      },
      customError: () => {
        errorToast();
        setLoading(false);
      },
      signOut,
      setDataUser,
    });
  };

  const handleSaveDraft = async ({ ad, uuid, setLoading, fetchData }) => {
    setLoading(true);
    const newData = parseAdToRemoveUselessFields(ad);
    await handleCRUD({
      endpoint: `ads/${uuid}`,
      method: "PUT",
      data: newData,
      successCallback: async () => {
        toastSuccess(
          t("saved-changes-ok"),
          { toastId: "ad_clone" },
          "check_circle"
        );
        await fetchData(ad);
        setLoading(false);
      },
      customError: () => {
        errorToast();
        setLoading(false);
      },
      signOut,
      setDataUser,
    });
  };

  const handleCloneAction = async ({
    adUuid,
    fetchData,
    setLoading,
    setOpen,
    ad,
    fromDetail,
  }) => {
    setLoading(true);
    handleStateChange(
      adStatus.CLONE,
      () => {
        toastSuccess(t("ad-drafted"), { toastId: "ad_clone" }, "content_copy");
      },
      adUuid,
      fetchData,
      setLoading,
      setOpen,
      ad,
      fromDetail
    );
  };

  const handlePublishAction = async ({
    adUuid,
    fetchData,
    setLoading,
    setOpen,
    ad,
    fromDetail,
  }) => {
    handleStateChange(
      adStatus.PUBLISHED,
      (response) => {
        if (
          response === adResponseStatus.CREATED ||
          response === adResponseStatus.UPDATED
        ) {
          toastSuccess(
            t("saved-changes-ok"),
            { toastId: "ad_clone" },
            "check_circle"
          );
        } else {
          toastSuccess(t("ad-moderated"), { toastId: "ad_clone" }, "block");
        }
      },
      adUuid,
      fetchData,
      setLoading,
      setOpen,
      ad,
      fromDetail
    );
  };

  const handleUnpublishAction = async ({
    adUuid,
    fetchData,
    setLoading,
    setOpen,
    ad,
    fromDetail,
  }) => {
    handleStateChange(
      adStatus.DRAFT,
      () => {
        toastSuccess(
          t("ad-unpublished"),
          { toastId: "ad_unpublish" },
          "disabled_visible"
        );
      },
      adUuid,
      fetchData,
      setLoading,
      setOpen,
      ad,
      fromDetail
    );
  };

  const handleBlockAction = async ({
    adUuid,
    fetchData,
    setLoading,
    setOpen,
    ad,
    fromDetail,
  }) => {
    handleStateChange(
      adStatus.BLOCKED,
      () => {
        toastSuccess(t("ad-blocked"), { toastId: "ad_block" }, "block");
      },
      adUuid,
      fetchData,
      setLoading,
      setOpen,
      ad,
      fromDetail
    );
  };

  const handleUnBlockAction = async ({
    adUuid,
    fetchData,
    setLoading,
    setOpen,
    ad,
    fromDetail,
  }) => {
    handleStateChange(
      adStatus.DRAFT,
      () => {
        toastSuccess(
          t("ad-unblocked"),
          { toastId: "ad_unblock" },
          "check_circle"
        );
      },
      adUuid,
      fetchData,
      setLoading,
      setOpen,
      ad,
      fromDetail
    );
  };

  const handleRestoreAction = async ({
    adUuid,
    fetchData,
    setLoading,
    setOpen,
    ad,
    fromDetail,
  }) => {
    handleStateChange(
      adStatus.DRAFT,
      () => {
        toastSuccess(t("ad-restored"), { toastId: "ad_share" }, "check_circle");
      },
      adUuid,
      fetchData,
      setLoading,
      setOpen,
      ad,
      fromDetail
    );
  };

  const handleDemandCoveredAction = async ({
    adUuid,
    fetchData,
    setLoading,
    setOpen,
    ad,
    fromDetail,
  }) => {
    handleStateChange(
      adStatus.COVERED,
      () => {
        toastSuccess(
          t("saved-changes-ok"),
          { toastId: "ad_covered" },
          "check_circle"
        );
      },
      adUuid,
      fetchData,
      setLoading,
      setOpen,
      ad,
      fromDetail
    );
  };

  const handleRemovedAction = async ({
    adUuid,
    fetchData,
    setLoading,
    setOpen,
    ad,
    fromDetail,
  }) => {
    handleStateChange(
      adStatus.REMOVED,
      () => {
        toastSuccess(
          t("saved-changes-ok"),
          { toastId: "ad_covered" },
          "check_circle"
        );
      },
      adUuid,
      fetchData,
      setLoading,
      setOpen,
      ad,
      fromDetail
    );
  };

  const handleTranslationAction = async ({
    uuid,
    newData,
    fetchData,
    setLoading,
  }) => {
    setLoading(true);
    delete newData.__typename;
    await handleCRUD({
      endpoint: `ads/${uuid}/translation`,
      method: "PUT",
      data: newData,
      successCallback: async () => {
        toastSuccess(
          t("saved-changes-ok"),
          { toastId: "ad_clone" },
          "check_circle"
        );
        await fetchData({ uuid });
        setLoading(false);
      },
      customError: () => {
        errorToast();
        setLoading(false);
      },
      signOut,
      setDataUser,
    });
  };

  const renderActions = (state, transactionsActive) => {
    let actions = [];
    switch (state) {
      case adStatus.PUBLISHED:
        actions.push(
          {
            component: shareAction,
          },
          {
            component: editAction,
          },
          {
            component:
              transactionsActive && parseInt(transactionsActive) > 0
                ? cantUnpublishAction
                : unpublishAction,
          },
          {
            component: cloneAction,
          },
          {
            component: blockAction,
          }
        );
        break;
      case adStatus.DRAFT:
        actions.push(
          {
            component: editAction,
          },
          {
            component: publishAction,
          },
          {
            component: cloneAction,
          }
        );
        break;
      case adStatus.REVIEW_PENDING:
        actions.push(
          {
            component: editAction,
          },
          {
            component: publishAction,
          },
          {
            component: cloneAction,
          },
          {
            component: blockAction,
          }
        );
        break;
      case adStatus.COVERED:
        actions.push(
          {
            component: editAction,
          },
          {
            component: cloneAction,
          }
        );
        break;
      case adStatus.BLOCKED:
        actions.push({
          component: unBlockAction,
        });
        break;
      case adStatus.REMOVED:
        actions.push({
          component: restoreAction,
        });
        break;
      default:
        actions.push({
          component: <ClearButton></ClearButton>,
        });
        break;
    }
    return actions;
  };

  const renderActionsForEdit = (state, isEdit = false) => {
    let actions = [];
    const { ad, id, permission } = props;
    if (permission && !permission()) return [];
    switch (state) {
      case adStatus.PUBLISHED:
        actions.push(
          {
            component:
              parseInt(ad?.transactionsActive) > 0
                ? cantUnpublishAction
                : unpublishAction,
          },
          {
            component: cloneAction,
          },
          {
            component: blockAction,
          }
        );
        break;
      case adStatus.DRAFT:
        isEdit
          ? actions.push({
              component: blockAction,
            })
          : actions.push({
              component: cloneAction,
            });
        break;
      case adStatus.REVIEW_PENDING:
        actions.push({
          component: blockAction,
        });
        break;
      case adStatus.COVERED:
        if (!isEdit) {
          actions.push({
            component: cloneAction,
          });
        }
        break;
      case adStatus.BLOCKED:
        break;
      case adStatus.REMOVED:
        break;
      default:
        actions.push({
          component: <ClearButton></ClearButton>,
        });
        break;
    }
    if (id && ad) {
      if (actions.length > 0) {
        actions.push({
          component: <hr style={{ margin: "-10px 0", width: "100%" }} />,
        });
      }
      actions.push({
        component: (
          <IconItem
            name={"translate"}
            onClick={() => {
              navigate(`/ads/${id}/translation`, {
                state: { originalTitle: ad?.originalTitle, id },
              });
            }}
            text={t("check-translations")}
            color={WARM_NEUTRAL_60}
          />
        ),
      });
    }

    return actions;
  };

  const renderActionsHelper = (state, isEdit = false, verificationState) => {
    const canPublish =
      parseStatusToPublishPermission(state, verificationState) &&
      checkFormRequiredFields(state)[0];
    const { permission } = props;
    if (permission && !permission()) return [];
    switch (state?.status) {
      case adStatus.PUBLISHED:
        return isEdit ? (
          <>
            <AdActionsButton type={"share"} handleOnClick={handleShare} />
            <Separator hideOnMobile={false} height={18} />
          </>
        ) : (
          <>
            <AdActionsButton type={"share"} handleOnClick={handleShare} />
            <Separator hideOnMobile={false} height={18} />
            <AdActionsButton type={"edit"} handleOnClick={handleEdit} />
            <Separator hideOnMobile={false} height={18} />
          </>
        );
      case adStatus.DRAFT:
        return isEdit ? (
          <>
            {!canPublish ? (
              <></>
            ) : (
              <>
                <AdActionsButton
                  type={"publish"}
                  handleOnClick={
                    state && parseInt(state?.amount) > 0
                      ? handlePublish
                      : handleDemandCovered
                  }
                />
                <Separator hideOnMobile={false} height={18} />
              </>
            )}
            <AdActionsButton type={"clone"} handleOnClick={handleCopy} />
            <Separator hideOnMobile={false} height={18} />
          </>
        ) : (
          <>
            <AdActionsButton type={"edit"} handleOnClick={handleEdit} />
            <Separator hideOnMobile={false} height={18} />
            {!canPublish ? (
              <></>
            ) : (
              canPublish && (
                <>
                  <AdActionsButton
                    type={"publish"}
                    handleOnClick={
                      state && parseInt(state?.amount) > 0
                        ? handlePublish
                        : handleDemandCovered
                    }
                  />
                  <Separator hideOnMobile={false} height={18} />
                </>
              )
            )}
          </>
        );
      case adStatus.REVIEW_PENDING:
        return isEdit ? (
          <>
            {!canPublish ? (
              <></>
            ) : (
              <>
                <AdActionsButton
                  type={"publish"}
                  handleOnClick={
                    state && parseInt(state?.amount) > 0
                      ? handlePublish
                      : handleDemandCovered
                  }
                />
                <Separator hideOnMobile={false} height={18} />
              </>
            )}
            <AdActionsButton type={"clone"} handleOnClick={handleCopy} />
            <Separator hideOnMobile={false} height={18} />
          </>
        ) : (
          <>
            {state?.type === "sale" &&
            verificationState !== orgStatus.KYC_COMPLETE ? (
              <></>
            ) : (
              canPublish && (
                <>
                  <AdActionsButton
                    type={"publish"}
                    handleOnClick={
                      state && parseInt(state?.amount) > 0
                        ? handlePublish
                        : handleDemandCovered
                    }
                  />
                  <Separator hideOnMobile={false} height={18} />
                </>
              )
            )}
            <AdActionsButton type={"edit"} handleOnClick={handleEdit} />
            <Separator hideOnMobile={false} height={18} />
          </>
        );
      case adStatus.COVERED:
        return (
          <>
            {parseInt(state?.amount) > 0 && (
              <>
                {!canPublish ? (
                  <></>
                ) : (
                  canPublish && (
                    <>
                      <AdActionsButton
                        type={"publish"}
                        handleOnClick={
                          state && parseInt(state?.amount) > 0
                            ? handlePublish
                            : handleDemandCovered
                        }
                      />
                      <Separator hideOnMobile={false} height={18} />
                    </>
                  )
                )}
              </>
            )}
            <AdActionsButton type={"edit"} handleOnClick={handleEdit} />
            <Separator hideOnMobile={false} height={18} />
          </>
        );
      case adStatus.BLOCKED:
        return (
          <>
            <AdActionsButton type={"unblock"} handleOnClick={handleUnBlock} />
          </>
        );
      case adStatus.REMOVED:
        return (
          <>
            <AdActionsButton type={"restore"} handleOnClick={handleREstore} />
          </>
        );
      default:
        return <></>;
    }
  };

  const renderActionsAsButtons = (state, isEdit = false, verificationState) => {
    return <Flex>{renderActionsHelper(state, isEdit, verificationState)}</Flex>;
  };

  return {
    renderActions,
    renderActionsForEdit,
    renderActionsAsButtons,
    handleShare,
    handleEdit,
    handleUnpublish,
    handleCopy,
    handleBlock,
    handleUnBlock,
    handlePublish,
    titleMessage,
    setTitleMessage,
    bodyMessage,
    setBodyMessage,
    okMessage,
    setOkMessage,
    errorMessage,
    open,
    setOpen,
    handleEditAction,
    handleCloneAction,
    handlePublishAction,
    handleUnpublishAction,
    handleCantUnpublish,
    handleBlockAction,
    handleUnBlockAction,
    handleRestoreAction,
    handleDemandCoveredAction,
    handleDemandCovered,
    handleRemovedAction,
    type,
    setType,
    handlePublishChanges,
    handleSaveDraft,
    handleTranslationAction,
  };
};
