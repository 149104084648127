import React, { useEffect, useState } from "react";
import { useGQL } from "../../utils/gql/hooks";
import { getCatalog, getCatalogNoTerm } from "../../model/filters";
import { useTranslation } from "../../utils/hooks";
import { AdManagementForm, validateForm } from "@scrapadev/scrapad-front-sdk";
import { getMaterialQuestions } from "../../model/ads";
import {
  getFavAddresses,
  getPreferencesPaymentMethods,
} from "../../model/business";
import _ from "lodash";
import { adStatus, modalTypes } from "../../utils/variables";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import { gqlWrapper } from "../../utils/gqlwrapper";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import {
  handleBulkOnChange,
  handleOnChange,
  uploadFile,
} from "../../utils/functions";
import { actions } from "../../providers/data/actions/data";
import { getCountries } from "../../model/countries";

const canEdit = (state, key, isEdit) => {
  if (!state.status) return false;
  if (state?.status === adStatus.BLOCKED || state?.status === adStatus.REMOVED)
    return false;
  if (
    state?.status === adStatus.COVERED ||
    (state?.status === adStatus.PUBLISHED &&
      state.transactionsActive &&
      state.transactionsActive > 0)
  ) {
    if (isEdit && (key === "amount" || key === "price" || key === "currency"))
      return true;
    else return false;
  }
  return isEdit ? true : false;
};
function AdFormV3({ state, dispatch, extraData, isMobile }) {
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const { t, i18n } = useTranslation(["common", "ads"], true);
  const [paymentMethodsData, setPaymentMethodData] = useState(undefined);
  const [errorFieldsList, setErrorFieldsList] = useState([]);
  const [modalType, setModalType] = useState(false);
  const [haveTriedToPublish, setHaveTriedToPublish] = useState(false);

  useEffect(() => {
    if (state.uuidOrg && !_.isEmpty(state) && _.isEmpty(paymentMethodsData)) {
      fetchPaymentMethodData({
        locale: i18n.language,
        org: state.uuidOrg,
      });
    }

    const errorFields = validateForm({
      state: state,
      basic: false,
      t: t,
      showToast: false,
    });
    if (!_.isEmpty(errorFields[1])) {
      setErrorFieldsList(errorFields[1]);
    } else {
      setErrorFieldsList([]);
    }
  }, [state]);

  const { data: currencyList } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "currency",
  });

  const { data: equipmentsData } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "equipments",
  });

  const { data: materialQuestionData, fetchData: fetchMaterialQuestions } =
    useGQL(getMaterialQuestions, {}, true);

  const { data: materialCompositionData } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "material-composition",
  });

  const fetchPaymentMethodData = async ({ locale = "es", org }) => {
    const data = await getPreferencesPaymentMethods({
      locale: locale,
      org: org,
    });
    setPaymentMethodData(data?.filter((e) => e.active) || []);
  };

  const fetchFavAddresses = async () => {
    const fn = await gqlWrapper(getFavAddresses, setDataUser, signOut);
    const response = await fn({
      locale: i18n.language,
      module: "locations",
      org: state.uuidOrg,
    });
    return response.getOrgLocations || [];
  };

  return (
    <>
      <AdManagementForm
        hideBottomControls={true}
        getCountries={async () => await getCountries(i18n.language)}
        avoidStepAutoRedirection={true}
        t={t}
        locale={i18n.language}
        state={state}
        canEdit={(k) => canEdit(state, k, extraData?.isEdit)}
        currencyList={currencyList || []}
        dispatch={({ payload }) => {
          dispatch({
            type: actions.UPDATE_INPUT,
            payload: payload,
          });
        }}
        equipmentsData={equipmentsData || []}
        materialQuestionData={materialQuestionData}
        materialCompositionData={materialCompositionData || []}
        paymentMethodsData={
          paymentMethodsData?.map((e) => ({
            uuid: e.uuidPaymentMethod,
            name: e.paymentMethodDesc,
            description: e.priceDesc,
          })) || []
        }
        errorFieldsList={errorFieldsList}
        onIncotermMoreInfo={() => setModalType(modalTypes.INCOTERM_INFO)}
        fetchGetFavAddresses={fetchFavAddresses}
        fetchMaterialQuestions={({ uuid }) =>
          fetchMaterialQuestions({ uuid, locale: i18n.language })
        }
        getCatalogFn={getCatalog}
        useGQL={useGQL}
        handleOnChange={(k) => (e) => {
          handleOnChange(k, dispatch)(e);
        }}
        handleBulkOnChange={(keys, values, deep) => {
          handleBulkOnChange(keys, dispatch, deep, values);
        }}
        uploadFile={(file) =>
          uploadFile({
            file: file,
            type: "ad",
          })
        }
        isMobile={isMobile}
        isPublishing={false}
        haveTriedToPublish={haveTriedToPublish}
        onLastButtonClick={() => {
          setHaveTriedToPublish(true);
        }}
        onPrevClick={() => {}}
      />
      <ModalFactory open={modalType} setOpen={setModalType} type={modalType} />
    </>
  );
}

export default AdFormV3;
