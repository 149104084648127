import { gql } from "@apollo/client";
import client from "../utils/gqlclient";

export async function getCountries(locale = "en") {
  const data = await client.query({
    query: gqlGetCountries,
    variables: {
      lang: locale,
    },
  });
  return data.data.countries || [];
}
const gqlGetCountries = gql`
  query ($lang: String!) {
    countries(lang: $lang) {
      name
      uuid
    }
  }
`;

