import { useTransactionsFormsProvider } from "../../../providers/transactions/TransactionsFormContext";
import { useTranslation } from "../../../utils/hooks";
import { buildFormNotes } from "./functions";
import { useUser } from "../../../providers/AuthHooks";
import {
  RoleType,
  formEvents,
  transactionFormNotesDefault,
} from "../../../utils/variables";
import { actions } from "../../../providers/transactions/actions/transactions-forms";
import { checkPermission, handleFormOK } from "../../../utils/functions";
import {
  Chip,
  InputText,
  Row,
  Spacer,
  toastError,
} from "@scrapadev/scrapad-front-sdk";
import MessageActionsMinimal from "./message/MessageActionsMinimal";
import { useCRUD } from "../../../utils/crud/hooks";

/**
 * Notes form for transactions.
 * @returns {JSX.Element}
 */
const NotesForm = ({ fetchData, permission, minimal }) => {
  const { t } = useTranslation("common");
  const { state, dispatch } = useTransactionsFormsProvider();
  const user = useUser();
  const { CRUD } = useCRUD();
  const checkdPermission = checkPermission(permission);

  const handleType = (t) => () => {
    let hiddenTypes = [];
    let newType = state?.destinations || [];
    if (newType?.includes(t)) {
      newType = newType.filter((e) => e !== t);
    } else {
      newType.push(t);
    }
    hiddenTypes = [...newType];

    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        destinations: newType,
        hiddenDestination: hiddenTypes.join("|"),
      },
    });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: actions.UPDATE_INPUT, payload: { loading: true } });
    const data = buildFormNotes(
      user.userUuid,
      document.getElementById("slug").value,
      state
    );
    try {
      await CRUD({
        external: true,
        endpoint: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        data: data,
        successCallback: async () => {
          if (state?.destinations.length === 0) {
            handleFormOK(t, fetchData);
            return;
          }
          const promises = state?.destinations.map((_) => {
            return CRUD({
              external: false,
              data: state.files.map((file) => {
                return {
                  fileUuid: file.uuid,
                  type: "back office - private",
                  to: "",
                  transactionUuid: state.uuidEvent,
                };
              }),
              endpoint: `integration/upload-to`,
              method: "POST",
              successCallback: () => {},
              customError: (error) => {
                console.log(error);
              },
            });
          });
          await Promise.all(promises);
          handleFormOK(t, fetchData);
        },
        errorCallback: (error) => {
          toastError(error.message);
        },
      });
    } catch (error) {
      toastError(error.message);
    } finally {
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: { ...transactionFormNotesDefault },
      });
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Row gap={10} style={{ marginBottom: "10px" }}>
        <span>{t("for")}: </span>
        <Chip
          type={
            state?.destinations?.includes(RoleType.commercial)
              ? "white-outlined-active"
              : "white-outlined"
          }
          handleClick={
            checkdPermission ? handleType(RoleType.commercial) : undefined
          }
          showIcon={state?.destinations?.includes(RoleType.commercial)}
          text={t("commercial")}
        />
        <Chip
          type={
            state?.destinations?.includes(RoleType.logistics)
              ? "white-outlined-active"
              : "white-outlined"
          }
          handleClick={
            checkdPermission ? handleType(RoleType.logistics) : undefined
          }
          showIcon={state?.destinations?.includes(RoleType.logistics)}
          text={t("logistic")}
        />
        <Chip
          type={
            state?.destinations?.includes(RoleType.finances)
              ? "white-outlined-active"
              : "white-outlined"
          }
          handleClick={
            checkdPermission ? handleType(RoleType.finances) : undefined
          }
          showIcon={state?.destinations?.includes(RoleType.finances)}
          text={t("finances")}
        />
        <Chip
          type={
            state?.destinations?.includes("required-document-for-payments")
              ? "white-outlined-active"
              : "white-outlined"
          }
          handleClick={
            checkdPermission
              ? handleType("required-document-for-payments")
              : undefined
          }
          showIcon={state?.destinations?.includes(
            "required-document-for-payments"
          )}
          text={t("payment_documentation")}
        />
      </Row>
      <InputText
        required
        readOnly={!checkdPermission}
        value={state.hiddenDestination}
        customValidityMessage={t("must-select-one-receiver")}
        style={{
          visibility: "hidden",
          height: 0,
          padding: 0,
          margin: minimal ? 0 : `0 0 .5rem 0`,
        }}
      />
      {!state.hiddenDestination && <Spacer height={10} />}
      <MessageActionsMinimal
        value={state.content}
        loading={state.loading}
        placeholder={t("write-here-notes")}
        filePlaceholder={t("select-drop-files")}
        fileDropPlaceholder={t("drop-files")}
        permission={permission}
      />
      <input
        type="hidden"
        id={"slug"}
        name={"slug"}
        value={formEvents.CREATE_NOTE}
      />
    </form>
  );
};

export default NotesForm;
